<template>
<aside class="fixAside">
<button v-for="(item,index) in asideList" :key="item.key" @mouseenter="mouseEnter(index)" @mouseleave="hoverId = null" @click="clickTo(item)" v-show="item.key !== 'top' || isShowTotop">
<section class="icon" :style="{'background-position': hoverId===index? `-${index*33}px -33px`:`-${index*33}px 0px`}"></section>
<p>{{item.name}}</p>
<section class="consult flexColumn" v-show="(hoverId===1||hoverId===2) && hoverId===index">
<p>{{item.title}}</p>
<p>{{item.num}}</p>
</section>

</button>
</aside>
</template>
<script>
export default {
  name: 'layoutAside',
  data() {
    return {
      asideList: [
        { key: 'online', name: '咨询' },
        { key: 'phonenum', name: '电话', title: '电话咨询', num: '400-169-8169' },
        { key: 'qq', name: 'QQ', title: 'QQ咨询', num: '3475907522' },
        { key: 'top', name: '回到顶部' },
      ],
      hoverId: null,
      isShowTotop: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    clickTo(item) {
      let { name } = item
      if (name === '回到顶部') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } else if (name === '咨询') {
        window.open('https://affim.baidu.com/unique_29804357/chat?siteId=14374257&userId=29804357&siteToken=520b588cd95edd77d27a035ef9054580', "aifanfan", "location=no, toolbar=no,titlebar=no,menubar==no,width=740,height=680")
      }
    },
    mouseEnter(index) {
      this.hoverId = index
    },
    scrollToTop(e) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.isShowTotop = scrollTop > 250 ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
.fixAside {
  position: fixed;
  z-index: 9999;
  right: 33px;
  top: 57%;
  width: 76px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 1px rgba(164, 183, 207, 0.4);
  border-radius: 4px;
  // transform: translateY(-50%);
  button {
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 70px;
    cursor: pointer;
    &:nth-child(1) {
      border-radius: 4px 4px 0 0;
    }
    &:nth-last-child(1) {
      border-radius: 0 0 4px 4px;
    }
    p {
      display: inline-block;
      font-size: 12px;
      color: #262626;
      line-height: 12px;
    }
    .icon {
      display: block;
      height: 33px;
      width: 33px;
      margin-bottom: 3px;
      background: url("/static/img/home/aside-sprite.png");
    }
    &:hover {
      background: #2592ff;
      color: #fff;
      fill: #fff;
      p {
        color: #fff;
      }
      .consult {
        p {
          color: #262626;
          font-size: 16px;
          line-height: 16px;
          &:nth-child(2) {
            color: #2592ff;
          }
        }
      }
    }
  }
}
// 小屏贴边
@media screen and (max-width: 1500px) {
  .fixAside {
    right: 0;
  }
}
.consult {
  position: absolute;
  width: 143px;
  height: 70px;
  box-shadow: 0px 2px 6px 1px rgba(164, 183, 207, 0.4);
  border-radius: 4px;
  left: -150px;
  top: 0;
  background-color: #fff;
  p{
    color: #262626;
    font-size: 16px !important;
    line-height: 16px !important;
    &:nth-child(2) {
      margin-top: 8px;
      color: #2592ff;
    }
  }
}
</style>
